import axios from 'axios'

const baseUrl = '/api/results'

const getAll = (challengeId) => {
  const request = axios.get(`${baseUrl}/${challengeId}`)
  return request.then(response => {
    console.log('results response', response.data)
    return(response.data)
  })
}

const post = (newResult) => {
  console.log('POST api/result', newResult)
  const request = axios.post(`${baseUrl}/${newResult.challengeId}`, newResult)
  return request.then(response => response.data)
}

const del = (challengeId) => {
  const request = axios.delete(`${baseUrl}/${challengeId}`)
  return request.then(response => response.data)
}

const resultservice = {
  getAll, post, del
}

export default resultservice