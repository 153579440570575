import { useState, useEffect } from 'react'
import { 
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom'
import loginService from './services/login.js'
import Header from './components/Header'
import Footer from './components/Footer'
import ChallengeList from './components/ChallengeList'
import Challenge from './components/Challenge'
import ChallengeCreate from './components/ChallengeCreate'
import Testlinks from './components/TestLinks'
import LandingPage from './components/LandingPage'
import { 
  ThemeProvider, Typography
} from '@material-ui/core/'
import {
  Skeleton
} from '@material-ui/lab'
import { 
  createMuiTheme 
} from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers'


const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#311b92'
    },
    secondary: {
      main: '#ee6002',
    },
  },
  spacing: 4,
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h3',
        h2: 'h3',
        h3: 'h4',
        h4: 'h4',
        h5: 'h5',
        h6: 'h5',
        subtitle1: 'h5',
        subtitle2: 'h5',
        body1: 'span',
        body2: 'span',
      },
    },
    MuiLink: {
      color: 'textPrimary',
      underline: 'always',
    }

  },
  // breakpoints: {
  //   values: {
  //     phone: 640,
  //   },
  // },
});

const IsAuthorised = (props) => {
  if (props.user) {
    return (
      <div>
        {props.children}
      </div>
    )
  }

  if (props.isAuthorising)
  return (
    <Skeleton animation="pulse">
      {props.children}
    </Skeleton>
  )

  return (<LandingPage/>)
}

function App() {
  const [user, setUser] = useState(null)
  const [isAuthorising, setIsAuthorising] = useState(true)

  useEffect(() => {
    setIsAuthorising(true)
    loginService
      .login()
      .then(user => {
        setIsAuthorising(false)
        setUser(user)
      })
      .catch(() => {
        setIsAuthorising(false)
      })
    }, [])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography>
            <Header user={user} />
            <IsAuthorised user={user} isAuthorising={isAuthorising}>
              <Switch>
                <Route path="/event/create">
                  <ChallengeCreate user={user} />
                </Route>
                <Route path="/event/:id">
                  <Challenge user={user} /> 
                </Route>
                <Route path="/">
                  <ChallengeList user={user} /> 
                </Route>
              </Switch>
            </IsAuthorised>
            <Footer />
            <Testlinks />
          </Typography>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
    
  );
}

export default App;
