import { 
  Typography, Link,
  Divider, List, ListItem, ListItemIcon,
  Box,
  Card, CardContent, CardActions, CardHeader  
} from '@material-ui/core/';
import PinDropIcon from '@material-ui/icons/PinDrop'
import DateIcon from '@material-ui/icons/Schedule'
import ResultAdd from './ResultAdd'
import ChallengeJoinLeave from './ChallengeJoinLeave.js';
import { formatDateTimeFromJSON } from '../utils/utils-DateTime'
import AthleteAvatars from './AthleteAvatars';

const StartDate = ({ challenge }) => {
  if (challenge.hasOwnProperty('startDateTime')) {
    return (
      <ListItem>
        <ListItemIcon>
          <DateIcon />
        </ListItemIcon>
        Starts: {formatDateTimeFromJSON(challenge.startDateTime)}
      </ListItem>
    )
  }
  return null
}

const EndDate = ({ challenge }) => {
  if (challenge.hasOwnProperty('endDateTime')) {
    return (
      <ListItem>
        <ListItemIcon>
          <DateIcon />
        </ListItemIcon>
        Ends: {formatDateTimeFromJSON(challenge.endDateTime)}
      </ListItem>
    )
  }
  return null
}

const Segment = ({ challenge }) => {
  if (challenge.hasOwnProperty('segment')) {
    return (
      <ListItem>
        <ListItemIcon>
          <PinDropIcon />
        </ListItemIcon>
          <Link href={`https://www.strava.com/segments/${challenge.segment.id}`}>
            {challenge.segmentName}
          </Link>
      </ListItem>
    )
  } 
  return null
}

const ChallengeDetails = ({ challenge, handleJoinLeave, setResults }) => {
  return (
    <Box py={2}>
      <Card>
        <CardHeader title={challenge.name} />
        <CardContent>
          <Typography>
            <List>
              <StartDate challenge={challenge} />
              <EndDate challenge={challenge} />
              <Segment challenge={challenge} />
            </List>
          </Typography>
        <Divider variant="fullWidth"/>
          <Typography color="textSecondary">
            {challenge.comment}
          </Typography>              
          <Divider variant="fullWidth"/>
          <AthleteAvatars athletes={challenge.athletes}/>
        </CardContent>
        <CardActions>
          {challenge.isJoined ?
            <ResultAdd 
              challengeId={challenge.id} 
              setResults={setResults}
            />
            : <></>
          }
          <ChallengeJoinLeave 
            handleJoinLeave={handleJoinLeave}
            challenge={challenge}
          />

        </CardActions>          
      </Card>
    </Box>
  )
}

export default ChallengeDetails