import {
  useState
} from 'react'
import { 
  Grid, 
  Button,
  TextField,
  Box,
  Card, CardContent, CardActions, CardHeader  
} from '@material-ui/core/'
import {
  KeyboardDatePicker, KeyboardTimePicker
} from '@material-ui/pickers'
import {
  Redirect
} from 'react-router-dom'
import SendIcon from '@material-ui/icons/Send'
import challengeService from '../services/challenges'
import addDate from 'date-fns/add'
import setDate from 'date-fns/set'
import SegmentStarredPicker from './SegmentStarredPicker'
import ClubPicker from './ClubPicker'

const ChallengeCreate = () => {
  const [ challenge, setChallenge ] = useState({ 
    // default start and end date/time
    startDateTime: setDate(addDate(Date.now(), {days: 1}), { hours: 0, minutes: 1, seconds:0, milliseconds: 0 }),
    endDateTime: setDate(addDate(Date.now(), {days: 7}), { hours: 23, minutes: 59, seconds:0, milliseconds: 0 }),
  })
  const [ isSubmitting, setIsSubmitting] = useState(false)

  const handleNameChange = (event) => {
    setChallenge({...challenge, name: event.target.value})
  }

  const handleCommentChange = (event) => {
    setChallenge({...challenge, comment: event.target.value})
  }

  const handleClubIdChange = (newValue) => {
    setChallenge({...challenge, clubId: newValue})
  }

  const handleSegmentIdChange = (newValue) => {
    setChallenge({...challenge, segmentId: newValue})
  }

  const handleStartDateChange = (date) => {
    setChallenge({...challenge, startDateTime: date })
  }

  const handleEndDateChange = (date) => {
    setChallenge({...challenge, endDateTime: date })
  }

  const submit = async () => {
    try {
      const newChallenge = await challengeService.post(challenge)
      console.log('result created', newChallenge)
      setChallenge(newChallenge)
    }
    catch (error) {
      setIsSubmitting(false)
      if (error.response.data.error) {
        console.log('error', error.response.data.error)
        // setMessage({ text: error.response.data.error, isError: true })
      }
    }
  }

  const validateChallenge = () => {
    return (
      challenge.name &&
      challenge.segmentId &&
      challenge.startDateTime > Date.now() &&
      challenge.endDateTime > challenge.startDateTime
    )
  }

  const handleAdd = () => {
    if (validateChallenge()) {
      setIsSubmitting(true)
      submit()
    }
  }

  return (
    <Box py={2}>
      <Card>
        <CardHeader title="Create an event" />
        <CardContent>
          <form 
            id="challengeForm" 
            // onSubmit={handleSubmit(handleAdd)}
          >
            <div>
              <TextField 
                name="name"
                label="Name" 
                id="name" 
                defaultValue={(challenge.name) ? challenge.name : ""}  
                // ref={register({ required: true })}
                helperText="Name is required"
                error={!challenge.name}
                onChange={handleNameChange}
                variant="filled"
                fullWidth
              />
              <ClubPicker clubId={challenge.clubId} setClub={handleClubIdChange}/>
              <SegmentStarredPicker segmentId={challenge.segmentId} setSegment={handleSegmentIdChange}/>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  variant="outline"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start date"
                  minDate={Date.now()}
                  error={challenge.startDateTime < Date.now()}
                  helperText="Must be in the future"
                  value={challenge.startDateTime}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  variant="filled"
                  margin="normal"
                  id="time-picker"
                  label="Start time"
                  error={challenge.startDateTime < Date.now()}
                  helperText="Must be in the future"
                  value={challenge.startDateTime}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                /> 
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-inline"
                  label="End date"
                  minDate={challenge.startDateTime ? challenge.startDateTime : Date.now()}
                  error={challenge.endDateTime < challenge.startDateTime}
                  helperText="Must be after start date and time"
                  value={challenge.endDateTime}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="End time"
                  error={challenge.endDateTime < challenge.startDateTime}
                  helperText="Must be after start date and time"
                  value={challenge.endDateTime}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                /> 
              </Grid>
              <TextField 
                name="comment"
                label="Comment" 
                id="comment" 
                defaultValue={(challenge.comment) ? challenge.comment : ""}  
                onChange={handleCommentChange}
                variant="filled"
                multiline
                fullWidth
              />
            </div>
          </form>
        </CardContent>
        <CardActions>
          <Button onClick={handleAdd} variant="contained" color="primary" endIcon={<SendIcon/>} disabled={!validateChallenge() || isSubmitting}>
              Add
            </Button>
        </CardActions>
        {challenge.id ? <Redirect to={`/event/${challenge.id}`} /> : <></> }       
      </Card>
    </Box>
  )
}

export default ChallengeCreate