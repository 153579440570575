import { useState, useEffect } from 'react'
import athleteService from '../services/athletes'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    useMediaQuery,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper,
    Avatar,
    Link,
    Grid,
    Fab,
    Box,
    Card, CardHeader, CardContent, CardActions,
    Button,
    Checkbox
  } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ChallengeJoinLeave from './ChallengeJoinLeave'
import AthleteAvatars from './AthleteAvatars'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const ChallengeRow = ({ challenge, handleJoinLeave }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <TableRow key={challenge.id}>
        <TableCell> 
            <Link href={`event/${challenge.id}`} >
              {challenge.name}
            </Link>
          </TableCell>
        <TableCell component="th" scope="row">
          <Grid container direction="row" alignItems="flex-start" >
            <Grid item>
              <Avatar 
                align="flex-start"
                alt={challenge.clubName} 
                src={challenge.clubAvatar} 
              />
            </Grid>
            <Grid item>
              <p>{challenge.clubName}</p>
            </Grid>
          </Grid>
          </TableCell>
          {mobile ||
            <>
              <TableCell>
                <AthleteAvatars athletes={challenge.athletes} />
              </TableCell>
              <TableCell>
                <ChallengeJoinLeave challenge={challenge} handleJoinLeave={handleJoinLeave} />
              </TableCell>
            </>
          } 
      </TableRow>
      {mobile && 
        <>
          <TableRow>
            <TableCell>
              <ChallengeJoinLeave challenge={challenge} handleJoinLeave={handleJoinLeave} />
            </TableCell>
            <TableCell>
              <AthleteAvatars athletes={challenge.athletes} max={4} />
            </TableCell>
          </TableRow>
        </>
      }
    </>
  )
}

const ChallengeList = ({ user }) => {
  const classes = useStyles()
  const [challenges, setChallenges] = useState([])
  const [showArchived, setShowArchived] = useState(false)

  const handleShowArchivedChange = (event) => {
    setShowArchived(!showArchived)
  }

  useEffect(() => {
    athleteService
      .getAll()
      .then(challenges => {
        console.log('challenges', challenges)
        setChallenges(challenges)
      })
    }, [])

  const handleJoinLeave = (challengeId) => {

    let isJoining
    const newChallenges = challenges.map((challenge) => {
      if (challenge.id === challengeId) {
        challenge.isJoined = !challenge.isJoined
        isJoining = challenge.isJoined
        if (isJoining) {
          // add the current user to the list of athletes on the challenge
          challenge.athletes.push({ 
            name: user.name,
            avatar: user.avatar,
            athleteId: user._id
          })
        } else {
          // remove the current user to the list of athletes on the challenge
          challenge.athletes.splice(challenge.athletes.findIndex(athlete => athlete.athleteId === user._id), 1)
        }
        console.log('user', user)
      }
      return (challenge)
    })

    let athleteServicePromise
    if (isJoining) {
      athleteServicePromise = athleteService.post(challengeId)
    } else {
      athleteServicePromise = athleteService.del(challengeId)
    }
    athleteServicePromise
      .then(() => {
        setChallenges(newChallenges)
      })
  }

  return (
    <div>
      <Box py={2}>
        <Card margin-top={20}>
          <CardHeader title="Events">
          </CardHeader>
          <CardContent>
            These are the events you can join, or have joined
            <br/>
            If you are an admin for a Strava club, you can create events for that club
          </CardContent>
          <CardActions>
            <Button 
              href="event/create"
              variant="contained" 
              color="default"
              startIcon={<AddIcon />}
            >
              Add event
            </Button>
          </CardActions>          
        </Card>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Event name</TableCell>
              <TableCell>Club</TableCell>
              <TableCell>Participants</TableCell>
              <TableCell>
                <Checkbox 
                  color="default"
                  onChange={handleShowArchivedChange}
                  checked={showArchived}
                />
                Show archived events
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {challenges
              .filter(challenge => (showArchived || challenge.status.description !== 'Archived'))
              .map(challenge => (<ChallengeRow key={challenge.id} challenge={challenge} handleJoinLeave={handleJoinLeave}/>))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Fab color="primary" aria-label="add" className={classes.fab}>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default ChallengeList