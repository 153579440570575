import formatDate from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'

export const formatDateTimeFromJSON = (JSONDate) => {
  const parsedDate = parseJSON(JSONDate)
  return(formatDate(parsedDate, 'd/MM/yyyy, p'))
}

export const formatElapsedTime = (s) => {

  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2
    return ('00' + n).slice(-z)
  }

  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60
  const hrs = (s - mins) / 60

  // return (
  //   ((s >= 60 * 60 * 60) ? hrs + ':' +
  //   ((s >= 60 * 60) ? pad(mins): mins)
  //   + ':' + 
  //   ((s >= 60) ? pad(secs) : secs)
  // )

  if (isNaN(s)) return ''

  return hrs + ':' + pad(mins) + ':' + pad(secs)
}
