import JoinIcon from '@material-ui/icons/AddBox';
import LeaveIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button'

const ChallengeJoinLeave = ({ challenge, handleJoinLeave }) => {
  if (challenge === undefined || challenge.isJoined === undefined || challenge.status === undefined ) return (<></>)
  if (challenge.isJoined || challenge.status.canJoin) {
    return (
      <Button 
        onClick={() => handleJoinLeave(challenge.id)}
        variant="contained" 
        color={challenge.isJoined ? "default" : "primary"} 
        startIcon={challenge.isJoined ? <LeaveIcon/> : <JoinIcon />}
      >
        {challenge.isJoined ? 'Leave' : 'Join'}
      </Button>
    )
  } else {
    return (<>{challenge.status.description}</>)
  }
}

export default ChallengeJoinLeave