import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import challengeService from '../services/challenges'
import athleteService from '../services/athletes'
import { formatDateTimeFromJSON, formatElapsedTime } from '../utils/utils-DateTime'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { 
  useMediaQuery,
  Table, TableBody,TableCell, TableContainer, TableHead, TableRow, 
  Grid,
  Link, 
  Box,
  Paper,
  Typography,
  Divider,
  Collapse,
  Avatar
} from '@material-ui/core/';
import ChallengeDetails from './ChallengeDetails'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  otherEfforts: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  },
}))


const ElapsedTime = ({ url, elapsedTime }) => {
  const formattedTime = formatElapsedTime(elapsedTime)
  if (url) {
    return (
      <Link href={url} >
        {formattedTime}
      </Link>
    )
  }
  // implied else
  return (<p>{formattedTime}</p>)
}

const OtherEffortList = ({ results, mobile }) => {
  if (results === undefined || results.length === 0) return null  
  console.log('OtherEffortList results', results)
  return (
    <Table>
      <TableBody>
        {results.map((result, i) => 
          <OtherResultRow 
            index={i}
            result={result}
            mobile={mobile}
          />)
        } 
        </TableBody>
      </Table>
    )
}

const OtherResultRow = ({ index, result, mobile }) => {
  const theme = useStyles()
  console.log('OtherResultRow index', index)

  return (
    <TableRow key={index} className={theme.otherEfforts}>
      <TableCell component="th" scope="row" className={theme.otherEfforts}>
        n/a {index}
      </TableCell>
      <TableCell className={theme.otherEfforts}>
        {result.athleteName}
      </TableCell>
      <TableCell className={theme.otherEfforts}>
        <ElapsedTime url={result.activityUrl} elapsedTime={result.elapsedTime} />
      </TableCell>
      { mobile ||
        <TableCell className={theme.otherEfforts}>
          {formatDateTimeFromJSON(result.activityDateTime)}
        </TableCell>
      }
    </TableRow>
  )

}

const ResultRow = ({ result, mobile }) => {
  const [open, setOpen] = useState(false);
  console.log('ResultRow', result)
  return (
    <>
      <TableRow key={result.id}>
        <TableCell component="th" scope="row">
          {result.position}
        </TableCell>
        <TableCell>
          <Grid container direction="row" alignItems="flex-start" >
            <Grid item>
              <Avatar           
                alt={result.athleteName} 
                src={result.athleteAvatar} 
              />
            </Grid>
            <Grid item>
              <p>{result.athleteName}</p>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <ElapsedTime url={result.activityUrl} elapsedTime={result.elapsedTime} />
        </TableCell>
        { mobile ||
          <TableCell>{formatDateTimeFromJSON(result.activityDateTime)}</TableCell>
        }
        <TableCell padding='none'>
          {result.otherEfforts.length === 0 ? <></> :
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OtherEffortList results={result.otherEfforts} mobile={mobile} />
          </Collapse> 
        </TableCell>
      </TableRow>
    </>
  )
}

const ResultList = ({ results, mobile }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Position</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Time</TableCell>
            {mobile ||
              <TableCell>When</TableCell>
            }
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(result => <ResultRow key={result.id} result={result} mobile={mobile}/>)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Challenge = ({ user }) => {
  const challengeId = useParams().id

  const [results, setResults] = useState([])
  const [challenge, setChallenge] = useState({})

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleJoinLeave = (challengeId) => {
    const newChallenge = {
      ...challenge,
      isJoined: !challenge.isJoined
    }


    let athleteServicePromise
    if (challenge.isJoined) {
      athleteServicePromise = athleteService.del(challengeId)
    } else {
      athleteServicePromise = athleteService.post(challengeId)
    }
    athleteServicePromise
      .then(() => {
        if (newChallenge.isJoined) {
          // add the current user to the list of athletes on the challenge
          newChallenge.athletes.push({ 
            name: user.name,
            avatar: user.avatar,
            athleteId: user._id
          })
        } else {
          // remove the current user from the list of athletes on the challenge
          newChallenge.athletes.splice(challenge.athletes.findIndex(athlete => athlete.athleteId === user._id), 1)
        }
        setChallenge(newChallenge)
      })
  }

  useEffect(() => {
    challengeService
      .get(challengeId)
      .then(response => {
        console.log('****** challenge', response)
        setResults(response.leaderboard)
        const challenge = {...response}
        delete challenge.leaderboard
        setChallenge(response)
      })
    }, [challengeId])

    if (challenge === undefined) return null

    return (
      <>
        <ChallengeDetails
          challenge={challenge}
          handleJoinLeave={handleJoinLeave}
          setResults={setResults}
          mobile={mobile}
          />
        <Divider variant="fullWidth"/>
        <Paper variant="outlined">
          <Box padding={4}>
            <Typography component="h2" padding={4}>Leaderboard</Typography>
          </Box>
          <Divider variant="middle" />
          <ResultList 
            results={results} 
            mobile={mobile}
          />
        </Paper> 
      </>
    )
}

export default Challenge