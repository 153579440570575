import {
  useState,
  useEffect
} from 'react'
import { 
  TextField,
  CircularProgress
} from '@material-ui/core/'
import {
  Autocomplete
} from '@material-ui/lab/'
import athleteClubs from '../services/athleteClubs'

const ClubPicker = ({ clubId, setClub }) => {
  const [ clubs, setClubs ] = useState([])
  const [ page, setPage ] = useState(1)
  const [ loading, setLoading ] = useState(true)
  const perPage = 10

  useEffect(() => {
    athleteClubs
      .getAll(page, perPage)
      .then(newClubs => {
        setClubs(clubs.concat(newClubs))
        if (newClubs.length === perPage) {
          setPage(page + 1)
        } else {
          setLoading(false)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

  const handleClubIdChange = async (event, newValue) => {
    if (newValue === null) {
      setClub(null)
      return
    }
    if (newValue.id) {
      setClub(newValue.id)
      return 
    }
  }

  return (
    <Autocomplete
      id="club-autocomplete"
      options={clubs}
      getOptionDisabled={(option) => !option.admin}
      getOptionLabel={(option) => {
        return option.name ? option.name : option
      }}
      onChange={handleClubIdChange}
      renderInput={(params) => (
        <TextField
          {...params} 
          name="club"
          label="Club" 
          id="club" 
          helperText="Club is required, you must be a club admin"
          error={!clubId}
          variant="filled"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )} 
    />
  )
}

export default ClubPicker