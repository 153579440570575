import axios from 'axios'

const baseUrl = '/api/athletes/starredsegments'

const getAll = (page, perPage) => {
  const request = axios.get(`${baseUrl}?page=${page}&per_page=${perPage}`)
  return request.then(response => response.data)
}

const athleteStarredSegmentService = {
  getAll
}

export default athleteStarredSegmentService