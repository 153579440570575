import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Avatar, Menu, MenuItem, AppBar, Toolbar,
  Typography, Link, Box
} from '@material-ui/core/'
import IconButton from '@material-ui/core/IconButton'
import HomeIcon from '@material-ui/icons/Home'
import {Link as RouterLink} from 'react-router-dom'
import connectWithStravaLogo from '../static/btn_strava_connectwith_orange.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const Header = ({ user, pageTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" component={RouterLink} to="/">
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Easy-TT 
          </Typography>
          <Login user={user}/>
        </Toolbar>
      </AppBar>
    </div>  
  )
}

const Login = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (user) {
    return (
      <div>
        <Avatar 
          align="center"
          alt={user.name} 
          src={user.profile_medium} 
          aria-controls="simple-menu" 
          aria-haspopup="true" 
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}><Link href="/auth/logout">Logout</Link></MenuItem>
        </Menu>
      </div>
    )
  } else {
    return (
      <Box height="25%" alignItems="center">
        <Link href="/auth/strava">
          <img src={connectWithStravaLogo} alt="Connect with Strava"/>
            {/* login with Strava */}
        </Link>
      </Box>
    )
  }
}

export default Header