import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Paper, Box, Typography
} from '@material-ui/core/'
import stravaLogo from '../static/api_logo_pwrdBy_strava.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const Footer = () => {
  const classes = useStyles();

  return (
    <Box py={2}>
      <Paper className={classes.paper} m={2}>
        <img src={stravaLogo} alt="Powered by Strava" height="50px" />
        <br/>
        <Box m={2}>
        <Typography color="textSecondary">
          This site uses cookies to function
          <br/>
          <Box fontSize={12} py={2}>
            © 2021 Warren Barton 
          </Box>
        </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default Footer