import { 
  Typography, 
  Box,
  Card, CardHeader, CardContent, 
  Link, Divider
} from '@material-ui/core'
import connectWithStravaLogo from '../static/btn_strava_connectwith_orange.png'

const LandingPage = (props) => {
  const bull = <span>•</span>;

  return (
    <Box py={2}>
      <Card>
        <CardHeader title="Welcome" />
        <CardContent>
        <Typography variant="h5" gutterBottom component="h2">
          cap{bull}ta{bull}re
        </Typography>
        <Typography gutterBottom color="textSecondary">
          verb
        </Typography>
        <Typography gutterBottom variant="body2" component="li">
          to skillfully obtain or capture
        </Typography>
        
      {/* </Card> */}
      <Divider variant="middle"/>
        <Typography>
          <br></br>
          <Typography gutterBottom variant="h5">
          To get started:
          </Typography>
          <ul>
            <Link href="/auth/strava">
              <img src={connectWithStravaLogo} alt="Connect with Strava"/>
            </Link>
            <li>
              You'll then see any existing events for Strava clubs you belong to
            </li>
            <li>
              Or, if you're a club admin, you can create events
            </li>
            <li>
              You can <Link href="https://www.strava.com/clubs/search">find 
              clubs on Strava</Link>
            </li>
            <li>
              Join an event (or two)
            </li>
            <li>
              Get out there and have fun :-)
            </li>
            <li>
              Activities that you do will be added to the event results automatically
            </li>
            <li>
              See how you did compared to your friends
            </li>
          </ul>
        </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export default LandingPage