import axios from 'axios'

const baseUrl = '/api/challenges'

const getAll = () => {
  const request = axios.get(baseUrl)
  return request.then(response => response.data)
}

const get = (challengeId) => {
  const request = axios.get(`${baseUrl}/${challengeId}`)
  return request.then(response => response.data)
}

const post = (challenge) => {
  const request = axios.post(`${baseUrl}/`, challenge)
  return request.then(response => response.data)
}

const del = (challengeId) => {
  const request = axios.delete(`${baseUrl}/${challengeId}`)
  return request.then(response => response.data)
}

const challengeService = {
  getAll, get, post, del
}

export default challengeService