import axios from 'axios'
const baseUrl = '/api/athletes'

const login = async () => {
  console.log('calling ', baseUrl)
  const response = await axios.get(baseUrl, {withCredentials: true})
  console.log(baseUrl, ' response ', response)
  return (response.data)
}

const loginService = {
  login
}

export default loginService