import React from 'react'
import challengeService from '../services/challenges'
import resultService from '../services/results'
import athleteService from '../services/athletes'

const initialChallenges = [
  {
    "name": "KL Challenge 1", "clubId": "100087", "comment": "Starts 16/3",
    "segmentId": "14837406", 
    startDateTime: new Date(2021, 2, 20, 8, 0, 0, 0),
    endDateTime: new Date(2021, 2, 29, 22, 0, 0, 0),
  },
  {"name": "KL Challenge 2", "clubId": "100087", "comment": "Finished 10/3", "segmentId": "14837406"}
]
const initialStravaResults = [
  'https://www.strava.com/activities/5004093221', // the slower one
  'https://www.strava.com/activities/4858975108', // the faster one
  'https://www.strava.com/activities/5214741057',
  'https://www.strava.com/activities/5174034819'
]

const addTestChallenges = () => {
  initialChallenges.map(async (challenge) => {
    console.log('POST ', challenge)
    const resp = await challengeService.post(challenge)
    console.log('done', resp)
  })
}

const addTestResults = async () => {
  const challengePromises = initialChallenges.map((challenge) => {
    console.log('POST ', challenge)
    return challengeService.post(challenge)
  })
  console.log('before promise.all challenges')
  const challenges = await Promise.all(challengePromises)
  console.log('after promise.all challenges')
  console.log('challenges', challenges)
  await challenges.reduce( async (previousPromise, nextChallenge) => {
    await previousPromise
    console.log('done')
    console.log('POSTing', nextChallenge.id)
    return athleteService.post(nextChallenge.id)
  }, Promise.resolve());
  console.log('after all challenge join', initialStravaResults)  
  await initialStravaResults.reduce( async (previousPromise, nextUrl) => {
    await previousPromise
    console.log('done')
    const result = {
      challengeId: challenges[1].id,
      activityUrl: nextUrl
    }
    console.log('POSTing ', result.activityUrl)
    return resultService.post(result)
  }, Promise.resolve());
  console.log('finished!!!')
}

const Testlinks = () => {
  console.log('NODE_ENV', process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'development') {
    return (
      <ul>
        <li>
          <a href='/api/athletes'>current user</a>
        </li>
        <li>
          <a href='/api/athletes/challenges'>current challenges</a>
        </li>
        <li>
          <button onClick={addTestChallenges}>
            Add test challenges only
          </button>
        </li>
        <li>
          <button onClick={addTestResults}>
            Add test challenges with results
          </button>
        </li>
      </ul>
    )
  }
  return null
}

export default Testlinks