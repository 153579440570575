import {
  useState,
  useEffect
} from 'react'
import { 
  TextField,
  CircularProgress
} from '@material-ui/core/'
import {
  Autocomplete
} from '@material-ui/lab/'
import athleteStarredSegmentService from '../services/athleteStarredSegments'

const SegmentStarredPicker = ({ segmentId, setSegment }) => {
  const [ segments, setSegments ] = useState([])
  const [ page, setPage ] = useState(1)
  const [ loading, setLoading ] = useState(true)
  const perPage = 10

  useEffect(() => {
    athleteStarredSegmentService
      .getAll(page, perPage)
      .then(newSegments => {
        setSegments(segments.concat(newSegments))
        if (newSegments.length === perPage) {
          setPage(page + 1)
        } else {
          setLoading(false)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

  const handleSegmentIdChange = async (event, newValue) => {
    if (newValue === null) {
      setSegment(null)
      return
    }
    if (newValue.id) {
      setSegment(newValue.id)
      return 
    }
  }

  return (
    <Autocomplete
      id="segment-autocomplete"
      options={segments}
      getOptionLabel={(option) => {
        return option.name ? option.name : option
      }}
      onChange={handleSegmentIdChange}
      renderInput={(params) => (
        <TextField
          {...params} 
          name="segment"
          label="Segment" 
          id="segment" 
          helperText="Required, choose from your starred segments"
          error={!segmentId}
          variant="filled"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )} 
    />
  )
}

export default SegmentStarredPicker