import { 
    Avatar
  } from '@material-ui/core'
import {
    AvatarGroup
  } from  '@material-ui/lab'


const AthleteAvatars = ({ max, athletes }) => {

  console.log('athletes', athletes)
  if (athletes === undefined || athletes.length === 0) return null

  return (
    <AvatarGroup max={max}>
      {athletes.map(athlete => {
        return (<Avatar 
          alt={athlete.name} 
          src={athlete.avatar} 
          key={athlete.athleteId}
        />)
      })}
    </AvatarGroup>
  )
}

export default AthleteAvatars