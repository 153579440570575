import axios from 'axios'

const baseUrl = '/api/athletes/challenges'

const getAll = () => {
  const request = axios.get(baseUrl)
  return request.then(response => response.data)
}

const post = (challengeId) => {
  const request = axios.post(`${baseUrl}/${challengeId}`)
  return request.then(response => response.data)
}

const del = (challengeId) => {
  const request = axios.delete(`${baseUrl}/${challengeId}`)
  return request.then(response => response.data)
}

const challengeService = {
  getAll, post, del
}

export default challengeService