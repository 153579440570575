import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import resultService from '../services/results.js'
import SendIcon from '@material-ui/icons/Send'

const ResultAdd = ({ challengeId, setResults }) => {
  const [result, setResult] = useState({ challengeId: challengeId })
  const [message, setMessage] = useState({ text: 'Strava URL is required', isError: true })
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setMessage({ text: 'Strava URL is required', isError: true })
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async () => {
    result.challengeId = challengeId
    try {
      const newResults = await resultService.post(result)
      console.log('result created', newResults)
      setResults(newResults.leaderboard)
      setOpen(false)
    }
    catch (error) {
      if (error.response.data.error) {
        console.log('error', error.response.data.error)
        setMessage({ text: error.response.data.error, isError: true })
      }
    }
  }

  const handleActivityUrlChange = (event) => {
    console.log('url changed', event.target.value)
    setResult({ ...result, activityUrl: event.target.value })
    setMessage({ text: '', isError: true })
  }

  const handleAdd = () => {
    if (result.activityUrl)
      {
        setMessage({ text: 'Processing...', isError: false })
        submit()
      }
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add activity
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add an activity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a result, please enter the URL for the Strava activity 
            <i>(e.g. https://www.strava.com/activities/5004093221)</i>
          </DialogContentText>
          <TextField 
            name="activityUrl"
            label="Strava Activity Url" 
            id="activityUrl"
            type="url" 
            defaultValue={(result.activityUrl) ? result.activityUrl : ""}  
            helperText={message.text}
            // helperText="test"
            error={message.isError}
            onChange={handleActivityUrlChange}
            autoFocus
            margin="dense"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleAdd} variant="contained" color="primary" endIcon={<SendIcon/>}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResultAdd